.cat_title, // content exchange
.main_label {

    position: relative;
    @include border-box();
    padding: 0 0 5px;
    margin: 0 12px 12px;
    display: block;
    font: $font_prim--light;
    @include font-size(24);
    @include line-height(40);
    @include letter-spacing(-25);
    text-align: center;
    color: $grey_01;
    text-transform: uppercase;

    > strong,
    > h1 {
        font: $font_prim--bold;
    }

    > h1 {
        display: inline-block;
    }

    @include min-screen($sm) {
        margin: 31px 25px 12px;
        padding: 0 0 10px;
    }

    @include min-screen($lg) {
        margin: 31px 15px 20px;
        padding: 0 0 10px;
        @include font-size(28);
    }

    @include min-screen($xlg) {
        margin: 51px 15px 30px;
        padding: 0 0 11px;
        @include font-size(36);
    }

    &--narrow {
        @include min-screen($xlg) {
            @include font-size(24);
        }
    }

    &--small {
        @include min-screen($lg) {
            @include font-size(28);
            @include line-height(40);
        }
    }

    &--line {
        border-bottom: 4px solid $grey_04;
    }

    &.is-section-avto {
        height: 100px;
        margin: 0 12px;
        @include min-screen($sm) {
            margin: 0 25px;
        }

        @include min-screen($lg) {
            margin: 0 0 20px 0;
        }

        figure {
            bottom: 1px;

            img {
                width: 100%;
                height: 100%;
            }
        }
    }
}

.cat_title { // content exchange
    @include min-screen($lg) {
        @include font-size(28);
        @include line-height(40);
    }
    border-bottom: 4px solid $grey_04;
}

.home__content_exchange .cat_title { // content exchange

    margin-left: 0;
    margin-right: 12px;

    @include max-screen($sm - 1) {
        margin-bottom: 20px;
        padding-bottom: 7px;
    }

    @include min-screen($sm) {
        margin-right: 25px;
        margin-bottom: 20px;
    }

    @include min-screen($lg) {
        @include font-size(28);
        @include line-height(40);
        margin-right: 0;
    }

    @include min-screen($xlg) {
        margin-bottom: 30px;
        @include font-size(36);
        @include line-height(40);
    }

    border-bottom: 4px solid $grey_04;
}

.column_sidebar  {

    .main_label  {
        margin: 40px 0 20px;
        padding-bottom: 0;
        @include font-size(24);

        @include min-screen($sm) {
            margin: 40px 15px 10px;
        }

        @include min-screen($lg) {
            margin: 44px 0 25px;
        }
    }
}

.g_id_signin{
    width: 100%;

    div{
        margin: 0 auto;

        iframe {
            margin: 0 auto !important;
        }
    }
}
