@import 'site_settings';
@import "includes/i_mixins";

$c: card;

.#{$c}--feed_sidebar {
    &:after {
        @include max-screen($lg - 1) {
            content: '';
            display: block;
            position: absolute;
            right: 0;
            top: 0;
            bottom: 0;
            width: 1px;
            height: auto;
            background-color: $grey_04;
        }
    }

    &:last-of-type {
        &:after {
            @include max-screen($lg - 1) {
                display: none;
            }
        }
    }

    &:first-of-type {
        @include min-screen($sm) {
            margin-left: 13px;
        }

        @include min-screen($lg) {
            margin-left: 0;
        }
    }

    @include max-screen($lg - 1) {
        display: inline-flex;
        white-space: normal;
        margin-top: 20px;
        float: none;
        flex-grow: 1;
        flex-shrink: 0;
        flex-basis: 250px;
    }

    @include min-screen($lg) {
        border-bottom: 1px solid $grey_04;
    }

    .#{$c}__wrap {
        text-align: left;

        @include max-screen($lg - 1) {
            margin-right: 12px;
            margin-left: 12px;
        }

        &:last-of-type {
            @include min-screen($sm) {
                margin-right: 25px;
            }

            @include min-screen($lg) {
                margin-right: 0;
            }
        }
    }

    .#{$c}__link {
      display: flex;
      flex-direction: column;
      height: 100%;
    }

    .#{$c}__photo_wrap {
        display: flex;
        margin-bottom: 8px;
    }

    .#{$c}__photo {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: 90px;
        // maknuti ovo kad dodu ispravne varijacije
        position: relative;
        display: block;
        width: 90px;
        height: 60px;
        background-color: #d8d8d8;
        overflow: hidden;

        @include min-screen($xlg) {
            flex-basis: 120px;
            width: 120px;
            height: 90px;
        }

        img {
            width: 100%;
            height: auto;
        }

        .#{$c}__figure {
            // maknuti ovo kad dodu ispravne varijacije
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 100%;
            height: auto;
        }
    }

    .#{$c}__title {
        padding-left: 8px;
        align-self: center;
        background-color: unset;
        font: $font_prim--med;
        @include font-size(18);
        @include line-height(20);
        @include letter-spacing(-18);
        color: $grey_01;
        transition: color 0.3s;

        strong {
            font: $font_prim--bold;
            @include font-size(18);
            @include line-height(20);
            @include letter-spacing(-18);
        }

        @include max-screen($lg - 1) {
            font: $font_prim--bold;
            font-size: 1.5rem;
            white-space: initial;
            align-self: auto;
        }
    }

    .#{$c}__text {
        margin-bottom: 8px;
        font: $font_prim;
        @include font-size(12);
        @include line-height(16);
        color: $grey_03;
    }

    .#{$c}__price {
        display: flex;
        align-items: center;
        margin-bottom: 8px;
        font: $font_prim--med;
        @include font-size(12);
        @include line-height(16);
        color: $grey_01;
        transition: color 0.3s;

        strong {
            padding-right: 4px;
            font: $font_prim--bold;
            @include font-size(18);
            @include line-height(20);
            @include letter-spacing(-18);
        }
    }

    .#{$c}__description_wrap {
        display: flex;
        margin-bottom: 16px;

        @include max-screen($lg - 1) {
            min-height: 40px;
        }

        .#{$c}__photo {
            flex-grow: 0;
            flex-shrink: 0;
            flex-basis: 40px;
            // maknuti ovo kad dodu ispravne varijacije
            position: relative;
            display: block;
            width: 40px;
            height: 40px;
            background-color: #d8d8d8;
            overflow: hidden;

            img {
                width: 100%;
                height: auto;
            }

            .#{$c}__figure {
                // maknuti ovo kad dodu ispravne varijacije
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                width: 100%;
                height: auto;
            }
        }

        .#{$c}__description {
            padding-left: 8px;
            font: $font_prim;
            @include font-size(12);
            @include line-height(16);
            color: $grey_03;
        }
    }

    .#{$c}__btn_wrap {
        display: block;
        margin: auto 0 20px;

        @include min-screen($lg) {
            margin-bottom: 17px;
        }
    }

    .#{$c}__btn {
        display: flex;
        transition: background-color 0.3s;
        background-color: $grey_04;
        justify-content: center;
        align-items: center;
        color: $blue_sec;

        figure {
            height: 30px;
            width: auto;

            img {
                width: auto;
                height: 100%;
            }
        }
    }

    &:hover {
        cursor: pointer;

        .#{$c}__title,
        .#{$c}__price strong,
        .#{$c}__price {
            @include min-screen($lg) {
                color: $grey_06;
            }
        }

        .#{$c}__btn {
            @include min-screen($lg) {
                background: $yellow_sec;
            }
        }
    }
}

.#{$c}--feed_sidebar + .#{$c}--feed_sidebar {
    @include min-screen($lg) {
        margin-top: 16px;
    }
}
