@import 'site_settings';
@import "includes/i_mixins";

$c: card;

.#{$c}--06 {
    .#{$c}__title {
        color: $grey_01;
    }

    &:hover .#{$c}__title {
        @include min-screen($lg) {
            color: $grey_06;
        }
    }

    .#{$c}__comments {
        left: 12px;

        &.is-bottom {
            display: none;
        }
    }

    @include max-screen($lg - 1) {
        .#{$c}__overtitle {
            margin-bottom: 0;
        }

        .#{$c}__title {
            display: block;
            margin-top: 12px;
            font: $font_prim--bold;
            @include font-size(18);
            @include line-height(20);
            @include letter-spacing(-10);
        }

        .#{$c}__photo_wrap {
            float: right;
            width: 120px;
            margin-bottom: 20px;
        }

        .#{$c}__data_wrap {
            position: static;
            overflow: hidden;
        }
        .#{$c}__data {
            position: relative;
            padding: 0 24px 0;
        }

        .#{$c}__comments {
            position: relative;
            display: none;

            &.is-bottom {
                display: inline-flex;
                left: 24px;
                margin-top: 5px;
            }
        }
    }

    @include max-screen($sm - 1) {
        .#{$c}__comments {
            &.is-bottom {
                display: inline-flex;
                left: 12px;
            }
        }

        .#{$c}__data {
            position: relative;
            padding: 0 12px 0;
        }
    }

    @include min-screen($xlg) {
        .#{$c}__link {
            height: 297px;
        }

        .#{$c}__data {
            position: static;
            padding: 0;
        }

        .#{$c}__overtitle_wrap {
            position: absolute;
            top: 0;
            left: 16px;
        }

        .#{$c}__title {
            display: inline-block;
            padding-top: 13px;
            font: $font_prim--bold;
            @include font-size(28);
            @include line-height(28);
            @include letter-spacing(-10);
            background: none;
        }

        .#{$c}__description_wrap {
            display: inline-block;
            padding: 12px 0 0;
        }

        .#{$c}__description {
            font: $font_prim;
            color: $grey_00;
            @include font-size(15);
            @include line-height(20);
        }

        .#{$c}__comments {
            display: none;

            &.is-bottom {
                display: flex;
                position: relative;
                left: 0;
            }
        }
    }
}

.#{$c}--06b {
    @include max-screen($lg - 1) {
        .#{$c}__overtitle {
            margin-bottom: 0;
        }

        .#{$c}__title {
            display: block;
            margin-top: 12px;
            font: $font_prim--bold;
            @include font-size(18);
            @include line-height(20);
            @include letter-spacing(-10);
            background-color: unset;
            color: $grey_01;
        }

        .#{$c}__photo_wrap {
            float: right;
            width: 120px;
            margin-bottom: 20px;
        }

        .#{$c}__description_wrap {
            display: none;
        }

        .#{$c}__data_wrap {
            position: static;
            overflow: hidden;
        }
        .#{$c}__data {
            position: relative;
            padding: 0 24px 0;
        }
    }

    @include max-screen($sm - 1) {
        .#{$c}__data {
            position: relative;
            padding: 0 12px 0;
        }
    }

    @include min-screen($lg) {
        .#{$c}__link {
            height: 297px;
        }

        .#{$c}__data {
            position: static;
            padding: 0;
        }

        .#{$c}__overtitle_wrap {
            position: absolute;
            top: 0;
            left: 16px;
        }

        .#{$c}__title {
            display: inline-block;
            padding-top: 12px;
            font: $font_prim--bold;
            @include font-size(22);
            @include line-height(24);
            @include letter-spacing(-22);
            background: none;
            color: $grey_01;
        }

        .#{$c}__description_wrap {
            display: inline-block;
            padding: 12px 0 0;
        }

        .#{$c}__description {
            font: $font_prim;
            color: $grey_00;
            @include font-size(15);
            @include line-height(20);
        }
    }

    @include min-screen($xlg) {
        .#{$c}__title {
            padding-top: 13px;
            font: $font_prim--bold;
            @include font-size(28);
            @include line-height(28);
            @include letter-spacing(-10);
            background: none;
        }
    }
}

.#{$c}--06c {
    @extend .card--06b;

    @include max-screen($lg - 1) {
        margin-bottom: 20px;

        .#{$c}__photo_wrap {
            float: none;
            width: 100%;
            margin-bottom: 0;
        }

        .#{$c}__data {
            position: absolute;
            bottom: 0;
            left: 0;
            padding: 12px 24px 15px;
            play: inline-block;
        }

        .#{$c}__title,
        .#{$c}__title_highlight {
            display: inline;
            margin: 0;
            padding: 0;
            @include font-size(28);
            @include line-height(28);
            @include letter-spacing(-28);
            color: $white;
            background: $grey_01;
        }
    }

    @include max-screen($sm - 1) {
        .#{$c}__data {
            padding: 12px 12px 15px;
        }
    }
}

.#{$c}--06a {
    position: relative;
    margin-bottom: 16px;

    &:after {
        content: '';
        position: absolute;
        display: block;
        width: auto;
        height: 1px;
        background-color: $grey_04;
        left: 12px;
        right: 12px;
        bottom: 0;

        @include min-screen($sm) {
            left: 24px;
            right: 24px;
        }

        @include min-screen($lg) {
            display: none;
        }
    }

    @include min-screen($sm) {
        padding-right: 0;
    }

    // card variation of card 6 styles for showing off feed cards on avto section

    .#{$c}__link {
      display: block;
      height: auto;
    }

    .#{$c}__photo_wrap {
        float: left;
        margin: 0 8px 0 12px;

        @include min-screen($sm) {
            width: 240px;
            margin: 0 8px 0 24px;
        }

        @include min-screen($lg) {
            float: right;
            width: 100%;
            margin: 0 0 8px;
        }

        .#{$c}__photo {
            // maknuti ovo kad dodu ispravne varijacije
            position: relative;
            display: block;
            width: 120px;
            height: 80px;
            background-color: #d8d8d8;
            overflow: hidden;

            @include min-screen($sm) {
                width: 240px;
                height: 160px;
            }

            @include min-screen($lg) {
                width: 226px;
                height: 151px;
            }

            @include min-screen($xlg) {
                width: 298px;
                height: 225px;
            }
        }

        .#{$c}__figure {
            // maknuti ovo kad dodu ispravne varijacije
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 100%;
            height: auto;
        }

        img {
            width: 100%;
            height: auto;
        }
    }

    .#{$c}__data_wrap {
        overflow: unset;
    }

    .#{$c}__description_wrap {
        display: block;
        padding: 0 12px;

        @include min-screen($sm) {
            padding: 0 24px;
        }

        @include min-screen($lg) {
            padding: 0;
        }
    }

    .#{$c}__title {
        min-height: 280px;
        display: block;
        margin-bottom: 8px;
        font: $font_prim--bold;
        @include font-size(18);
        @include line-height(20);
        @include letter-spacing(-18);
        color: $grey_01;
        transition: color 0.3s;
        background-color: unset;

        @include max-screen($lg - 1) {
            min-height: 80px;
            margin-top: 0;
        }
    }

    .#{$c}__text {
        display: block;
        margin-bottom: 8px;
        font: $font_prim;
        @include font-size(12);
        @include line-height(16);
        color: $grey_03;
        transition: color 0.3s;
    }

    .#{$c}__price {
        margin-bottom: 8px;
        font: $font_prim--med;
        @include font-size(12);
        @include line-height(16);
        color: $grey_01;

        strong {
            padding-right: 4px;
            font: $font_prim--bold;
            @include font-size(18);
            @include line-height(20);
            @include letter-spacing(-18);
            color: $grey_01;
        }
    }

    .#{$c}__dealer {
        display: block;
        margin-bottom: 16px;
        min-height: 40px;

        .#{$c}__figure {
            float: left;
            margin-right: 8px;
            // maknuti ovo kad dodu ispravne varijacije
            position: relative;
            display: block;
            width: 40px;
            height: 40px;
            overflow: hidden;
            background-color: #d8d8d8;
        }

        img {
            // maknuti ovo kad dodu ispravne varijacije
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 100%;
            height: auto;
        }

        .#{$c}__description {
            display: block;
            padding-left: 48px;
            font: $font_prim;
            @include font-size(12);
            @include line-height(16);
            color: $grey_03;
        }
    }
    .#{$c}__btn_wrap {
        margin-bottom: 16px;

        .btn--view_more {
            display: flex;
            background-color: $grey_04;
            justify-content: center;
            align-items: center;
            text-align: center;
            color: $blue_sec;

            @include min-screen($xlg) {
                height: 50px;
                padding: 0 30px;
            }

            figure {
                height: 35px;
                bottom: 1px;
                position: relative;

                img {
                    width: auto;
                    height: 100%;
                }
            }
        }
    }

    .#{$c}__btn {
        transition: background-color 0.3s;
    }

    &:hover {
        cursor: pointer;

        .#{$c}__title,
        .#{$c}__price strong,
        .#{$c}__price {
            @include min-screen($lg) {
                color: $grey_06;
            }
        }

        .#{$c}__btn {
            @include min-screen($lg) {
                background: $yellow_sec;
            }
        }
    }
}
